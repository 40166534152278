.rbc-header{
    background-color: rgb(101, 122, 224) !important;
    color: white ;
    font-size: medium !important;
    font-weight: 400 !important;
    padding: 7px;
}

.rbc-toolbar button{
    background-color: #94a0df !important;
    color: white !important ;
    font-weight: 400 !important;
    border: 1px solid #7a85be ;
    border-radius: 10px !important;
}
.rbc-toolbar button:hover{
    background-color: #576acc !important;
    color: white !important ;
    border: 1px solid #636ea7 ;
}


.rbc-toolbar button.rbc-active {
    background-color: #3f4c8f !important;
    color: white !important ;
    border: 1px solid #2e3974 ;
    border-radius: 10px !important;
}

.rbc-toolbar button.rbc-active:hover{
    background-color: #576acc !important;
    color: white !important ;
}

.rbc-btn-group{
    background-color: white !important;
    color: white ;
    font-size: medium !important;
    font-weight: 400 !important;
    
   
}

.rbc-btn-group button{
    margin-right: 3px;
    
   
}

.rbc-day-bg{
    background-color: white;
}
.rbc-today{
    background-color: #c9e1e9;
}

.rbc-show-more{
    background-color: transparent;
    color: #413c3c;
    font-weight: 400;
    padding-top: 2px;
}

.rbc-toolbar{
    gap: 5px;
}

.rbc-toolbar .rbc-toolbar-label{
    font-weight: 400 !important;
    font-size: xx-large;
    color: #384588 !important;
    
}


