.body-cont{
    display: flex;
    gap: 300px;
}

.charts{
    width: 80%;
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr  ;
    grid-template-rows: 2fr 2fr ;
    gap: 50px;
    position: relative;
    
   
}