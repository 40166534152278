/*------------------- image side ------------------------ */

.loginPage {
    display: flex;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
}

.imgContainer {
    width: 70vw;
    background-color: rgba(128, 128, 128, 0.067);
}

.imgContainer img {
    height: 100%;
}

/*------------------- form side ------------------------ */

.formContainer {
    display: flex;
    flex-direction: column;
    width: 30vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.formContainer h2 {
    color: rgba(0, 0, 255, 0.703);

}


.formContainer h2 span {
    color: rgb(60, 60, 60);
}

form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 80%;
}

.inputGrp {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.inputGrp label{
    font-weight: 600;
}

.spanCont {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.input-text {
    width: 100%;
    height: 45px;
    border-radius: 10px;
    color: black;
    border: 2px solid  #4c7ea373;
    padding-left: 10px;
    font-weight: 500;
}

.input-text:focus {
    border-color: #0766AD;
    outline: none; 
}

.checkboxCont {
    display: flex;
    gap: 7px;
    font-weight: 500;
}

.loginButton {
    width: 100%;
    height: 38px;
    border: none;
    background-color: rgba(0, 0, 255, 0.703);
    color: white;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
    margin: 10px 0;
}

.loginButton:hover {
    background-color: rgba(5, 5, 218, 0.703);
}

a {
    text-decoration: none;
    color: rgba(0, 0, 255, 0.703);
}

label {
    font-size: 15px;
}

.registerCont {
    display: flex;
    justify-content: space-between;
}

.registerCont p {
    font-weight: 500;
}