@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
   
}

body{
    height: 100vh;
    width: 100vw;
    top: 0;
    right: 0;
    overflow-x: hidden
}
.leaflet-container{
    width: 100%;
    height: 20vh;
  }

  .leaflet-container .leaflet-control-attribution {
    display: none;
  }
  
  input::file-selector-button {
    font-weight: bold;
    color: white;
    padding: 0.5em;
    border: thin solid grey;
    border-radius: 3px;
    background-color: rgb(0, 0, 41)
  }
  

  

@tailwind base;
@tailwind components;
@tailwind utilities;