.node__root > circle {
    fill: rgb(114, 114, 243);
  }
  
  .node__branch > circle {
    fill: rgb(114, 114, 243);
  }
  
  .node__leaf > circle {
    fill: rgb(114, 114, 243);
    /* Let's also make the radius of leaf nodes larger */
    
  }