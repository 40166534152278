.dndflow {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
  }
  
  .dndflow aside {
    border-right: 1px solid #eee;
    padding: 15px 10px;
    font-size: 12px;
    background: #fcfcfc;
  }
  
  .dndflow aside .description {
    margin-bottom: 10px;
    color: gray;
    font-size: 15px;
  }
  
  .dndflow .dndnode {
    height: 20px;
    padding: 4px;
    border: 1px solid #1a192b;
    border-radius: 2px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab;
    margin-right: 10px;
    font-weight: 500;
  }
  
  .dndflow .dndnode.input {
    border-color: #0041d0;
  }
  
  .dndflow .dndnode.output {
    border-color: #ff0072;
  }
  
  .dndflow .reactflow-wrapper {
    flex-grow: 1;
    height: 90vh;
  }
  
  .dndflow .selectall {
    margin-top: 10px;
  }
  
  @media screen and (min-width: 768px) {
    .dndflow {
      flex-direction: row;
    }
  
    .dndflow aside {
      width: 20%;
      max-width: 250px;
    }
  }

  .react-flow__node-custom {
    font-size: 10px;
    width: 180px;
    background: #f5f5f6;
    color: #222;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
    border-radius: 2px;
  }
  
  .react-flow__node-custom .react-flow__handle {
    top: 24px;
    right: -15px;
    width: 6px;
    height: 10px;
    border-radius: 2px;
    background-color: #778899;
  }
  
  .react-flow__node.circle {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
  }
  
  .react-flow__node.annotation {
    border-radius: 0;
    text-align: left;
    background: white;
    border: none;
    line-height: 1.4;
    width: 225px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
  }
  
  .react-flow__node.annotation .react-flow__handle {
    display: none;
  }
  
  .custom-node__header {
    padding: 8px 10px;
    border-bottom: 1px solid #e2e8f0;
  }
  
  .custom-node__body {
    padding: 10px;
  }
  
  .custom-node__select {
    position: relative;
    margin-bottom: 10px;
  }
  
  .custom-node__select select {
    width: 100%;
    margin-top: 5px;
    font-size: 10px;
  }
  .button-panel{
    margin-left: 20px; 
  }

  .custom-action{
    font-size: 10px;
    width: 250px;
    height: 170px;
    background: #f5f5f6;
    color: #222;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
    border-radius: 10px;
    padding: 5px 20px;
    
  }

  .custom-action-default-start{
    font-size: 20px;
    width: 200px;
    height: 60px;
    background: rgb(53, 53, 252);
    color: white;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
    border-radius: 10px;
    padding: 10px 0;
  }
  .custom-action-default-end{
    font-size: 20px;
    width: 200px;
    height: 60px;
    background: rgb(153, 0, 0);
    color: white;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
    border-radius: 10px;
    padding: 10px 0;
  }

  .custom-action-default{
    font-size: 20px;
    width: 200px;
    height: 60px;
    background: #f5f5f6;
    color: #222;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
    border-radius: 10px;
    padding: 10px 0;
    
  }

  .custom-action-prop{
    font-size: 20px;
    width: 200px;
    height: 100px;
    background: #f5f5f6;
    color: #222;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
    border-radius: 10px;
   padding: 1px 15px;
  }

 